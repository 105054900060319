.app-content {
  flex: auto;
  display: flex;
  flex-direction: column; }

.body-layout {
  display: flex;
  flex: auto;
  flex-direction: row;
  height: 0; }

.side-menu {
  flex: none;
  width: 20rem;
  overflow-y: auto; }
  .side-menu .section:first-child {
    border-top: 1px solid rgba(0, 0, 0, 0.125); }

.body-content {
  display: flex;
  flex-direction: column;
  flex: auto;
  z-index: 5; }
