.app-header {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  color: white; }

.app-title h3 {
  vertical-align: middle;
  display: inline; }

.app-title img {
  width: auto;
  max-height: 60px; }

.search-box input[type='search'] {
  width: 130px;
  box-shadow: 0.1rem;
  box-sizing: border-box;
  border-radius: 2px;
  font-family: inherit;
  padding: 0px 15px;
  width: 200px;
  -webkit-transition: width 250ms ease-in-out;
  -moz-transition: width 250ms ease-in-out;
  -o-transition: width 250ms ease-in-out;
  transition: width 250ms ease-in-out; }
  .search-box input[type='search']:focus {
    width: 450px; }

#searchboxicon {
  padding: 0px 0px 0px 0px !important; }
