.category-auth-input {
  letter-spacing: 0.5rem;
  size: 6rem;
  font-size: 2rem; }

.btn-circle.btn-xl {
  width: 70px;
  height: 70px;
  padding: 5px 5px;
  border-radius: 35px;
  font-size: 12px;
  line-height: 1.33;
  z-index: 6;
  position: absolute;
  right: 25px;
  bottom: 35px;
  font-style: inherit;
  box-shadow: 2px 5px 3px #888888;
  font-weight: bolder; }
