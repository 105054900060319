.hide-section-articles-on-drag {
  transition: all 0.5s ease-in;
  overflow: hidden; }
  .hide-section-articles-on-drag .articles-list {
    display: none; }

input[type='articleName'],
textarea {
  background-color: white; }

.section-wrapper {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  width: 100%;
  min-height: 100%; }

.section {
  border-top-width: 0;
  padding: 0; }
  .section h6 {
    color: #6c757d; }

.article {
  padding-left: 3em;
  border-radius: 0;
  border-width: 1px 0 0 0; }

.default-cursor {
  cursor: default; }

.draggable-article:hover {
  background-color: #fff; }

.section-header {
  color: #000; }
  .section-header h6 {
    font-weight: 700; }

.list-group-item:hover {
  background-color: #ffffff; }

.list-group-item {
  padding: 0; }
  .list-group-item a {
    display: block;
    padding: 0.5rem 1rem;
    width: 100%; }

.sorting-form-tabs a.active {
  font-weight: bold;
  opacity: 1; }

.sorting-form-tabs a {
  opacity: 0.5; }

.sorting-form-tabs .nav-link {
  cursor: default; }
