.editor-wrapper {
  display: flex;
  flex: auto;
  flex-direction: column;
  padding: 1rem;
  height: 0; }

.article-container {
  display: flex;
  flex-direction: column;
  flex: auto; }
  .article-container iframe {
    flex: auto; }

@media print {
  .article-container {
    display: flex;
    flex-direction: column;
    flex: auto;
    background-color: white;
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    margin: 0;
    padding: 15px;
    font-size: 14px;
    line-height: 18px;
    float: inherit;
    overflow: visible; }
    .article-container iframe {
      flex: auto; } }

.btn-outline-secondary {
  background-color: rgba(255, 255, 255, 0.425); }

.spinner {
  display: flex;
  position: absolute;
  left: 50%;
  top: 50%;
  height: 60px;
  width: 60px;
  margin: 0px auto;
  -webkit-animation: rotation 0.6s infinite linear;
  -moz-animation: rotation 0.6s infinite linear;
  -o-animation: rotation 0.6s infinite linear;
  animation: rotation 0.6s infinite linear;
  border-left: 6px solid rgba(0, 174, 239, 0.15);
  border-right: 6px solid rgba(0, 174, 239, 0.15);
  border-bottom: 6px solid rgba(0, 174, 239, 0.15);
  border-top: 6px solid rgba(0, 174, 239, 0.8);
  border-radius: 100%; }

#overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 4; }

@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(359deg); } }

@-moz-keyframes rotation {
  from {
    -moz-transform: rotate(0deg); }
  to {
    -moz-transform: rotate(359deg); } }

@-o-keyframes rotation {
  from {
    -o-transform: rotate(0deg); }
  to {
    -o-transform: rotate(359deg); } }

@keyframes rotation {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(359deg); } }
