.main {
  background-color: #1976d2;
  min-height: 50vh;
  height: 100%;
  width: 100%; }

.title {
  border-bottom: 5px solid #e3f2fd; }

.intro-content {
  width: 80%;
  min-width: 15rem; }
