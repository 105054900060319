.edit-mode {
  background-color: #dc3545;
  color: white; }

/* Define an animation behavior */
@keyframes spinner {
  to {
    transform: rotate(360deg); } }

/* This is the class name given by the Font Awesome component when icon contains 'spinner' */
.fa-spinner {
  /* Apply 'spinner' keyframes looping once every second (1s)  */
  animation: spinner 1s linear infinite; }
